import React, { Component } from 'react';
import Image from 'react-bootstrap/Image';


class About extends Component{

    constructor(props){
        super(props);

        this.state = {
            isModalOpen: false,
            modalIndex: 0,
            modalHeaders: ['Reprocessed Granules', 'Plastic Scrap', 'Machineries of Recycling Plant',
            'Stock Lot of plastic for Reuse', 'Waste management Consultancy']
        }

        this.handleRepro = this.handleRepro.bind(this);
        this.handlePlastic = this.handlePlastic.bind(this);
        this.handleMachinery = this.handleMachinery.bind(this);
        this.handleStock = this.handleStock.bind(this);
        this.handleWaste = this.handleWaste.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(){
        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
    }

    handleRepro(){
        this.setState({
            isModalOpen: true,
            modalIndex: 0
        })
    }

    handlePlastic(){
        this.setState({
            isModalOpen: true,
            modalIndex: 1
        })
    }

    handleMachinery(){
        this.setState({
            isModalOpen: true,
            modalIndex: 2
        })
    }

    handleStock(){
        this.setState({
            isModalOpen: true,
            modalIndex: 3
        })
    }

    handleWaste(){
        this.setState({
            isModalOpen: true,
            modalIndex: 4
        })
    }

    render(){
        return (
            <div className='aboutContainer'>
                <div className="aboutDiv" style={{padding: "20px 0 0 30px"}}>
                    <div className="aboutDiv" style={{padding: "20px 0 0 30px"}}>
                        <div className="row">
                            <h3 className="aboutHeading"> <b> About Mars International, Inc. </b> </h3>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <p className="aboutText" >
                                    We entered plastic recycling more than 2 decades ago and in that time, we have participated in multiple international exhibitions and have long-term partners all over the world. <br/><br/>
                                    We offer sourcing, processing and marketing services in recycling and reusing plastic. These include:
                                </p>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <Image src="assets/images/Exhibition.jpg" alt="Exhibition" height="300px" width="300px" roundedCircle="true"></Image>
                            </div>
                        </div>
                    </div>
                    <div className="aboutDiv" style={{padding: "20px 0 0 30px"}}>
                        <div className="row">
                            <h3 className="aboutHeading"> <b>Reprocessed Granules </b> </h3>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-xl-4 col-md-5 col-sm-12">
                                <Image src="assets/images/AboutRepro.jpeg" alt="Repro Image" height="300px" width="300px" roundedCircle="true"></Image>
                            </div>
                            <div className="col-xl-6 col-md-7 col-sm-12">
                                <p className="aboutText" ><br/><br/>We represent plastic reprocessed granules seller plants covering major areas around the world with an increasingly strong hold in Asia.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutDiv" style={{padding: "20px 0 0 30px"}}>
                    <div className="row">
                        <h3 className="aboutHeading"> <b> Plastic Scrap </b> </h3>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <p className="aboutText" ><br/><br/> We represent plastic scrap sellers located in Europe, USA and Asia Pacific. </p>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <Image src="assets/images/AboutPlasticScrap.jpeg" alt="Plastic Scrap Image" height="300px" width="300px" roundedCircle="true"></Image>
                        </div>
                    </div>
                </div>
                <div className="aboutDiv" style={{padding: "20px 0 0 30px"}}>
                    <div className="row">
                        <h3 className="aboutHeading"><b>Machineries of recycling plant </b></h3>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-xl-4 col-md-5 col-sm-12">
                            <Image src="assets/images/RecycleMachine.jpg" alt="Recycle Machine" height="300px" width="300px" roundedCircle="true"></Image>
                        </div>
                        <div className="col-xl-6 col-md-7 col-sm-12">
                            <p className="aboutText" > <br/><br/>We can suggest the right machinery suitable for the recycling plant according to the availability of scrap, man power and budgetary limitation. <br/> We do not represent any machinery manufacturer.</p>
                        </div>
                    </div>
                </div>
                <div className="aboutDiv" style={{padding: "20px 0 0 30px"}}>
                    <div className="row">
                        <h3 className="aboutHeading"> <b> Stock lot of plastic for reuse </b> </h3>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <p className="aboutText" ><br/>We get information about the stock lot of plastic goods for their one time or regular sale. These stock lots can be reused for various other applications. This helps in reducing the scrap.</p>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <Image src="assets/images/AboutStockLot.jpeg" alt="StockLotImg" height="300px" width="300px" roundedCircle="true"></Image>
                        </div>
                    </div>
                </div>
                <div className="aboutDiv" style={{padding: "20px 0 0 30px"}}>
                    <div className="row">
                        <h3 className="aboutHeading"> <b>Waste Management </b> </h3>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-xl-4 col-md-5 col-sm-12">
                            <Image src="assets/images/WasteMgmt.jpg" alt="Waste Management Image" height="300px" width="300px" roundedCircle="true"></Image>
                        </div>
                        <div className="col-xl-6 col-md-7 col-sm-12">
                            <p className="aboutText" ><br/>We are eager to share our experience with the generator or collector of waste, for reducing, recycling and reusing of their waste/scrap product. <br/>We can do long term contract for conversion of a company's own waste material for reuse either in one of their own product or for reuse in a product of another company.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;    