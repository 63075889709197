import React, { Component } from 'react';
import Main from './components/Main';
import './App.css';
import './App_small.css';
import {HashRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
// provider makes react store available to all components
import { ConfigureStore } from './redux/configureStore';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMedal } from '@fortawesome/free-solid-svg-icons'

const store = ConfigureStore();
library.add(faMedal)

class App extends Component{

  render(){
    return (
        <Provider store = {store}>
          <HashRouter>
              <Main/>
          </HashRouter>
        </Provider>
    );
  }
}

export default App;
