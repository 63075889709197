import React, { useState, memo } from "react"
import {ComposableMap, Geographies, Geography, Marker, Graticule, Sphere} from "react-simple-maps"
import { geoUrl } from '../shared/baseUrl';
import ReactTooltip from "react-tooltip";
import { Loading } from "./Loading";
import { ErrorFetching } from "./ErrorFetching";
import { PatternCircles } from "@vx/pattern";

function isInArray(arr, geo){
  const val = geo.properties.NAME;
  return arr.some(item => item.name === val);
}

const MapChart = (props) => {

    const [text, setText] = useState("");

    if(props.isLoading){
        return (
            <div className="mapChartDiv">
                <Loading/>
            </div>
        )
    } else if(props.errMess){
        return (
            <div className="mapChartDiv">
                <ErrorFetching/>
            </div>
        );
    } else{
        const markers = props.locations.map((location) => {
        return {
            markerOffset: 15,
            name: location.title,
            coordinates: [location.longitude, location.latitude]
        }
        });

        return (
        <div className="mapChartDiv">
            <ComposableMap data-tip=""  projection="geoEqualEarth">
            <Sphere stroke="#DDD" />
            <Graticule stroke="#DDD" />
            <PatternCircles id="circles" height={5} width={5} stroke={'#d3d3d3'} strokeWidth="3"/>
            <PatternCircles id="circlesMarked" height={5} width={5} stroke={'#002855'} strokeWidth="3"/>
            <PatternCircles id="circlesHighlighted" height={5} width={5} stroke={'#e1c699'} strokeWidth="3"/>

            <Geographies geography={geoUrl} >
                {({ geographies }) =>
                geographies.map(geo => {
                    const isHighlighted = isInArray(markers, geo);
                    return (<Geography
                        key={geo.rsmKey}
                        geography={geo}
                        // className={isHighlighted ? "marked" : "unmarked"}
                        onMouseEnter={() => {
                            const { NAME } = geo.properties;
                            setText(NAME)
                        }}
                        onMouseLeave={() => {
                            setText("")
                        }}
                        // marked: #ffb84d
                        fill= {isHighlighted ? "url('#circlesMarked')" : "url('#circles')"}
                        enableBackground= "false"
                        style={{
                            hover: {
                                fill: "url('#circlesHighlighted')"
                            },
                            pressed: {
                                fill: "url('#circlesHighlighted')"
                            }
                        }}
                    />);
                })
                }
            </Geographies>
            {markers.map(({ name, coordinates, markerOffset }) => (
            <Marker key={name} coordinates={coordinates}>
                <g
                    stroke="black"
                    strokeWidth="1"
                    fill="#FF8C00"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="translate(-12, -24)"
                >
                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                </g>
            </Marker>
            ))}
            </ComposableMap>
            <ReactTooltip>{text}</ReactTooltip>
        </div>
        )
    }
  }

export default memo(MapChart);