import React from 'react';
import ReactDOM from 'react-dom';

// Import to use bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-social/bootstrap-social.css';

// import this after bootstrap so you van overwrite bootstrap
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// ReactDOM renders APP and attaches to root element of index.html page
ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
