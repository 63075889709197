import React from 'react';
import { Card, Button, CardTitle, CardBody, CardImg, CardImgOverlay } from 'reactstrap';
import { Link } from 'react-router-dom';
import Accordion from "./Accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from './Loading';
import { ErrorFetching } from "./ErrorFetching";
import { NothingToShow } from './NothingToShow';


    // Can simply accept props also. But if you know what prop you're getting then get that directly
    function RenderItem({item}){
        return (
            <Card key={item.id} className="itemCard">
                <Link to= {`/items/${item.id}`}>
                    {/* fetch image from server here */}
                    <CardImgOverlay className="cardImgOverlay">
                        <span id="certSpanCirc">
                            {item.bestSeller? <FontAwesomeIcon icon="medal" size="lg"/> : <span/>}
                        </span>
                    </CardImgOverlay>
                    <CardImg className="cardImgDark" src={item.imageUrl} alt={item.title} />
                    <CardBody className="cardBody">
                        <Button className="cardButton"><CardTitle className="cardTitleProd" style={{textTransform: "uppercase", fontWeight: "bold"}}> {item.title} </CardTitle></Button>
                    </CardBody>
                </Link>
            </Card>
        );
    }

function Product(props){

    if(props.isLoading){
        return (
            <div className="productDiv">
                <Loading/>
            </div>
        )
    } else if(props.errMess){
        return (
            <div className="productDiv">
                <ErrorFetching/>
            </div>
        );
    } else{
        return (
        <div className="productDiv">
            <Accordion allowMultipleOpen>
            {props.products.map(product => {
                const itemList = props.items.filter((item) => item.productTitle === product.title)
                return (<div key={product.id} label={product.title} smallText={itemList.length + " items"} className="container" isOpen={product.title === props.products[0].title}>
                    <div key={product.title} className="row">    
                    {itemList.length > 0?
                        itemList.map(item => 
                            <div key={item.title} className="col-6 col-sm-4 col-md-3 col-xl-2">
                                <RenderItem item={item}/>
                            </div>
                        )
                        : 
                        <NothingToShow/>
                    }
                    </div>
                </div>
                );
            })}
            </Accordion>
        </div>
        );
    }
}

export default Product;