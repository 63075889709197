import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Loading } from './Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SideMenu extends Component{

    constructor(props){
        super(props);

        this.renderTable = this.renderTable.bind(this);

    }

    renderTable(items, selectedItemId){
        return items.map((item) => {
            return (
                <tr key={item.id} className={(item.id === selectedItemId).toString()}>
                    <td className="sideMenuTD">
                        <Link key={item.id} to= {`/items/${item.id}`} className={"linkItem " + (item.id === selectedItemId).toString()}>
                            {item.title}
                            <span style={{marginLeft: "10px"}}>
                                {item.bestSeller? <FontAwesomeIcon icon="medal" size="sm" color="black"/> : <span/>}
                            </span>
                        </Link>
                    </td>
                </tr>
            )
        })
    }  

    render(){
        return (
            <div className="container">
                <table id='items' className='sideTable'>
                    {this.props.items && this.props.items.length>0 ?
                        <>
                        <thead className="tableHead">{this.props.items[0].productTitle}</thead>
                        <tbody>
                            {this.renderTable(this.props.items, this.props.selectedItemId)}
                        </tbody>
                        </>
                        :
                    <Loading/>
                    }
                </table>
            </div>
        )
    }

}

export default SideMenu;