import http from "./http-common";

class FeedbackDataService {
  create(data) {
    return http.post("/feedbacks", data);
  }

  update(id, data) {
    return http.put(`/feedbacks/${id}`, data);
  }
}

export default new FeedbackDataService();