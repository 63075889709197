// Config file to setup whatever required to communicate with server
var url = "https://mars-backend-heroku-b893da0c7a6b.herokuapp.com/api/";
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
    url = 'http://localhost:8080/api/';
}

export const googleApiKey = 'AIzaSyBcSsiwMMDWB897I2iMnWbGxLC8eNocZCE';
export const baseUrl = url;
export const logoUrl = 'https://firebasestorage.googleapis.com/v0/b/marsapp-c8e65.appspot.com/o/images%2FMars%20grey%20logo.png?alt=media&token=d94fa915-dbb3-4045-922f-704ca6da86a0';
export const logoTransparentUrl = 'https://firebasestorage.googleapis.com/v0/b/marsapp-c8e65.appspot.com/o/images%2FMars%20white%20logo.png?alt=media&token=068a8a62-6457-43fb-924f-8908b51fe712';
export const sendgrid_key = "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";
export const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-50m.json";

export const backgroundUrls = {
    "homeBackground": "https://firebasestorage.googleapis.com/v0/b/marsapp-c8e65.appspot.com/o/images%2FbackgroundImages%2FHomeBG_dark.jpg?alt=media&token=4e8a8dfd-4806-41ee-89d5-41ba3e3963ef",
    "aboutBackground": "https://firebasestorage.googleapis.com/v0/b/marsapp-c8e65.appspot.com/o/images%2FbackgroundImages%2FAboutUsBG.jpg?alt=media&token=a24386ed-f097-41e8-9736-7053c96eb24d",
    "productBackground": "https://firebasestorage.googleapis.com/v0/b/marsapp-c8e65.appspot.com/o/images%2FbackgroundImages%2FProductBG.png?alt=media&token=dcd35172-2a31-4626-b806-9d79a38d5971",
    "applicationBackground": "https://firebasestorage.googleapis.com/v0/b/marsapp-c8e65.appspot.com/o/images%2FbackgroundImages%2FApplicationBG.jpg?alt=media&token=1fadd51b-d828-4da0-8a42-27fa6e8438d0",
    "contactBackground": "https://firebasestorage.googleapis.com/v0/b/marsapp-c8e65.appspot.com/o/images%2FbackgroundImages%2FContactBG.png?alt=media&token=fd56a24e-3a0e-493d-9dad-30577c1d78fd",
    "mapBackground": "https://firebasestorage.googleapis.com/v0/b/marsapp-c8e65.appspot.com/o/images%2FbackgroundImages%2FMapBG.jpg?alt=media&token=9ce5b172-675f-40d8-abad-0697cdee367e"
};
