import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';

class Home extends Component{

    render(){

        return (
            <div className="homeDiv">
                <div className="row justify-content-center homeHeading">             
                    <span className="homeBigText">Moving recyclable, resuable, recoverable plastic around the world</span>
                </div>
                <hr style={{border: "1px solid black", width: "25%"}}/>
                <div className="row ">             
                    <div className="offset-md-1 col-sm-8 col-md-5 homePartDiv">
                        <h3 className="homePartHeading">Buy from us</h3>
                        <p className="homePartPara">We sell good quality, inexpensive products.</p>
                        <Link to = "/products" onClick={() => this.props.onChangeLink("products", 2)}>
                            <Image className="homeImg" src="assets/images/BuyFromUs.jpeg" roundedCircle="true"></Image>
                        </Link>
                    </div>
                    <div className="col-sm-8 col-md-5 homePartDiv" id="sellToUs">
                        <h3 className="homePartHeading">Sell to us</h3>
                        <p className="homePartPara">We buy products from all over the world.</p>
                        <Link to = "/contactus" onClick={() => this.props.onChangeLink("contact", 4)}>
                            <Image className="homeImg" src="assets/images/SellImage_port.jpg" roundedCircle="true"></Image>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
