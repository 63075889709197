import {createStore, combineReducers, applyMiddleware} from 'redux';
import { createForms } from 'react-redux-form';
import { Products } from './products';
import { Applications } from './applications';
import { Items } from './items';
import { Samples } from './samples';
import { Locations } from './locations';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import { InitialFeedback } from './forms';

export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            products: Products,
            items: Items,
            samples: Samples,
            locations: Locations,
            applications: Applications,
            ...createForms({
                feedback: InitialFeedback
            })
        }),
        // now both thunk and logger are available in our app
        applyMiddleware(thunk, logger)
    );

    return store;
}