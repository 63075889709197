import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    smallText: PropTypes.string.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const { onClick, props: { isOpen, label, smallText } } = this;

    return (
      <div key="randomKey" className="accordTitle">
        <div key="randomKey3" onClick={onClick} style={{ cursor: "pointer", color: "black", background: "rgb(245, 242, 242, 0.8)", padding: "10px", borderTop: "1px solid black", borderBottom: "1px solid black"}}>
            <span className="itemTitle"> {label} </span><br/>
            <span className="itemTitle2"> {smallText} </span>
          <div style={{ float: "right" }}>
            {!isOpen && <span>&#9660;</span>}
            {isOpen && <span>&#9650;</span>}
          </div>
        </div>
        {isOpen && (
          <div key="randomKey2" className="accordSection">
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;
