import React, { Component } from 'react';
import { Card, CardImg, CardBody, Modal, ModalHeader, ModalBody, CardSubtitle, 
    Button, Label, Col, Row, CardImgOverlay} from 'reactstrap';
import SideMenu from './SideMenu';
import { Loading } from './Loading';
import { Control, Form, Errors } from 'react-redux-form';
import FeedbackDataService from '../shared/feedback.service';

// checks to see that value length is >0
const required = (val) => val && val.length;
// ensures length <= max Length
const maxLength = (len) => (val) => !(val) || (val.length <= len)
const minLength = (len) => (val) => !(val) || (val.length >= len)
class RenderSample extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            sample: props.sample,
            productTitle: props.productTitle,
            isEnquiredModalOpen: false,
            enquireModalTitle: '',
            error: ''
        }

        this.handleEnquire = this.handleEnquire.bind(this);
        this.handleSubmitEnquiry = this.handleSubmitEnquiry.bind(this);
        this.toggleEnquireModal = this.toggleEnquireModal.bind(this);
    }

    handleEnquire(e){
        this.setState({
            isEnquiredModalOpen: true,
            enquireModalTitle: e.currentTarget.dataset.id,
        })
    }

    handleSubmitEnquiry(values) {
        var data = {
            first_name: values.firstname,
            last_name: values.lastname,
            contact: values.telnum,
            email: values.email,
            feedback: values.message
          };
      
        FeedbackDataService.create(data)
            .then(response => {
                //no error
                this.toggleEnquireModal();
                console.log(response);
            })
            .catch(e => {
              console.log(e.response.data);
              this.setState({
                  error: e.response.data
              });
              this.toggleEnquireModal();
        });
    }

    toggleEnquireModal(){
        this.setState({
            isEnquiredModalOpen: !this.state.isEnquiredModalOpen,
            error: null
        })
    }

    render(){
        const {sample, isEnquiredModalOpen, enquireModalTitle} = this.state;
        
        const scrap = sample.scrap;
        const filter = sample.filter;
        const mfi = sample.mfi;

        const samplesDiv = 
            <div className="col-6 col-md-4 col-xl-3" style={{marginTop: "10px", padding: "5px", backgroundColor: "white"}}>
                <Card className="sampleCard">
                    <CardImgOverlay className="cardImgOverlayItem">
                        <div className="cardImgOnclick" height="80%" data-id={sample.title} onClick={this.props.handleClickImage}>
                            <span id="certSpanSq">
                                {/* {sample.bestSeller? <FontAwesomeIcon icon="medal" size="lg" color="#007bff"/> : <span/>} */}
                                {sample.bestSeller? <img alt="BestSeller" style={{maxHeight: "50px", maxWidth: "50px"}} src="assets/images/bestSellerIcon.png"/> : <span/>}
                            </span>
                        </div>
                        <button className="enquireDiv" data-id={sample.title} onClick={this.handleEnquire}>Enquire</button>
                    </CardImgOverlay>
                    <CardImg className="cardImg" width="50%" src={sample.imagesUrl[0]} alt={sample.title}/>
                    <CardBody style={{color: "black"}}>
                        <CardSubtitle> <b>{sample.title} </b>
                        </CardSubtitle>
                            {scrap? <> <b>Scrap: </b> {scrap} <br/> </> : null}
                            {mfi? <> <b>MFI: </b> {mfi} <br/></> : null}
                            {filter? <> <b>Filter: </b> {filter}<br/> </> : null}
                    </CardBody>
                </Card>
            </div>
        
        const errorDiv = this.state.error ? 
            <Row className="form-group">
                <Label htmlFor="message" md={2}>Error</Label>    
                <p style={{color: "red"}}> {this.state.error} </p>
            </Row> : <div/>

        const modalWithDiv = <React.Fragment>
            {samplesDiv}
            <Modal isOpen={isEnquiredModalOpen} toggle={this.toggleEnquireModal}>
                <ModalHeader closebutton="true">Send an instant enquiry</ModalHeader>
                <ModalBody>
                <Form model="feedback" onSubmit={(values) => this.handleSubmitEnquiry(values)}>
                        {/* Allows you to define a row of the form */}
                        <Row className="form-group">
                            <Label htmlFor="firstname" md={2}>First Name</Label>
                            <Col md={10}>
                                <Control.text model=".firstname" id="firstname" name="firstname"
                                    placeholder="First Name"
                                    className="form-control"
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(15)
                                    }}
                                        />
                                <Errors
                                    className="text-danger"
                                    model=".firstname"
                                    show="touched"
                                    messages={{
                                        required: 'Required',
                                        minLength: 'Must be greater than 2 characters',
                                        maxLength: 'Must be 15 characters or less'
                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="lastname" md={2}>Last Name</Label>
                            <Col md={10}>
                                <Control.text model=".lastname" id="lastname" name="lastname"
                                    placeholder="Last Name"
                                    className="form-control"
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(15)
                                    }}
                                        />
                                <Errors
                                    className="text-danger"
                                    model=".lastname"
                                    show="touched"
                                    messages={{
                                        required: 'Required',
                                        minLength: 'Must be greater than 2 characters',
                                        maxLength: 'Must be 15 characters or less'
                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="telnum" md={2}>Contact</Label>
                            <Col md={10}>
                                <Control.text model=".telnum" id="telnum" name="telnum"
                                    placeholder="Tel. Number"
                                    className="form-control"
                                    pattern="^[0-9 +-]*$"
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(20)
                                    }}
                                        />
                                <Errors
                                    className="text-danger"
                                    model=".telnum"
                                    show="touched"
                                    messages={{
                                        required: 'Required',
                                        minLength: 'Must be greater than 2 numbers',
                                        maxLength: 'Must be 15 numbers or less'                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="email" md={2}>Email</Label>
                            <Col md={10}>
                                <Control.text model=".email" id="email" name="email"
                                    placeholder="Email"
                                    className="form-control"
                                    validators={{
                                        required
                                    }}
                                        />
                                <Errors
                                    className="text-danger"
                                    model=".email"
                                    show="touched"
                                    messages={{
                                        required: 'Required'
                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="message" md={2}>Your enquiry</Label>
                            <Col md={10}>
                                <Control.textarea model=".message" id="message" 
                                    key={`${Math.floor((Math.random() * 1000))}-min`}
                                    name="message"
                                    className="form-control"
                                    rows="5"
                                    defaultValue={"Hi! I would like to enquire about "+enquireModalTitle}
                                />
                            </Col>
                        </Row> 
                        {errorDiv}
                        <Row>
                            <Col>
                                <Button type="submit" color="primary">
                                    Send Enquiry
                                </Button>
                            </Col>
                            <Col>
                                <Button color="secondary" onClick={this.toggleEnquireModal} style={{float: "right" }}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>

        return modalWithDiv;
    }
}

class Item extends Component{

    constructor(props){
        super(props);

        this.state = {
            hasLoaded: false,
            items: [],
            isModalOpen: false,
            modalIndex: 0,
            selectedItem: {},
            selectedSamples: [],
            modalUrls: [],
            modalTitles: []
        }

        this.keyPressed = this.keyPressed.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.nextImageClicked = this.nextImageClicked.bind(this);
        this.prevImageClicked = this.prevImageClicked.bind(this);
        this.initialiseVariables = this.initialiseVariables.bind(this);
    }

    componentDidMount(){
        document.addEventListener("keyup", this.keyPressed, false);
        if(this.props.items.length > 0){
            this.setState({
                items: this.props.items
            },
                this.initialiseVariables
            );
        }
    }

    componentWillUnmount(){
        document.removeEventListener("keyup", this.keyPressed, false);
    }

    keyPressed(event){
        
        if(event.keyCode === 37) {
            this.prevImageClicked();
        } else if(event.keyCode === 39){
            this.nextImageClicked();
        }
    }

    toggleModal(){
        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
    }

    handleClickImage(e){

        var indexClicked = this.state.modalTitles.indexOf(e.currentTarget.dataset.id);
        if(indexClicked === -1){
            indexClicked=0;
        }

        this.setState({
            isModalOpen: true,
            modalIndex: indexClicked
        })
    }
    
    prevImageClicked(){
        var index = this.state.modalIndex;
        if(index === 0){
            index = this.state.modalTitles.length
        }
        this.setState({
            modalIndex: index -1
        })
    }

    nextImageClicked(){
        var index = this.state.modalIndex+1;
        if(index === this.state.modalTitles.length){
            index = 0
        }
        this.setState({
            modalIndex: index
        })
    }

    initialiseVariables(){
        var selectedItem = this.state.items.filter((item) => item.id === this.props.itemId)[0];
        var selectedSamples = this.props.samples.filter((sample) => sample.itemTitle === selectedItem.title)
        var modalUrls = [];
        var modalTitles = [];
        selectedSamples.forEach((sample) => {
            modalUrls = modalUrls.concat(sample.imagesUrl[0]);
            modalTitles = modalTitles.concat(sample.title);
        });

        this.setState({
            selectedItem: selectedItem,
            selectedSamples: selectedSamples,
            modalUrls: modalUrls,
            modalTitles: modalTitles,
            hasLoaded: true,
        })
    }
    

    render(){
        if(this.state.hasLoaded){
            const {modalIndex, isModalOpen, modalUrls, modalTitles, selectedItem, selectedSamples } = this.state;

            return (
                <>
                    <div className="row itemContainer">
                        <div className="col-sm-12 col-md-3 col-xl-3">
                            <SideMenu items={this.props.items.filter((item) => item.productTitle === selectedItem.productTitle)} 
                                        selectedItemId={selectedItem.id}/>
                        </div>
                        <div className="col-sm-12 col-md-9 col-xl-9">
                            <h1> {selectedItem.title} </h1>
                            <hr style={{border: "1px solid black", width: '50%', marginLeft: 0}} />
                            <div className="row">   
                                {selectedSamples.length > 0? 
                                    selectedSamples.map(sample => 
                                        <RenderSample key={sample.title} 
                                            sample={sample} 
                                            productTitle={selectedItem.productTitle} 
                                            handleClickImage = {this.handleClickImage}
                                            toggleModal = {this.toggleModal}/>
                                    )
                                :
                                    <div style={{marginLeft: "20px"}}>
                                        <p>Sorry, there are no items in this category!</p> 
                                    </div>
                                }
                                <Modal isOpen={isModalOpen} toggle={this.toggleModal} width='700px' height='700px'>
                                    <ModalHeader>{modalTitles[modalIndex]}</ModalHeader>
                                    <ModalBody>
                                        <Card className="modalCard">
                                            <CardImg src={modalUrls[modalIndex]} height='100%' alt=''/>
                                            <CardImgOverlay>
                                                <Button onClick={this.prevImageClicked} id="prevImage"><i className="fa fa-chevron-circle-left fa-lg"></i></Button>
                                                <Button onClick={this.nextImageClicked} id="nextImage"><i className="fa fa-chevron-circle-right fa-lg"></i></Button>
                                            </CardImgOverlay>
                                        </Card>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else{
            return (
                <Loading/>
            );
        }
        
    }
}

export default Item;