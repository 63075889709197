import React, { Component } from 'react';
import Home from './Home';
import Product from './Product';
import Application from './Application';
import Item from './Item';
import Header from './Header';
import Footer from './Footer';
import Contact from './Contact';
import About from './About';
import MapChart from './MapChart';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import { fetchProducts, fetchItems, fetchSamples, fetchLocations, fetchApplications } from '../redux/ActionCreators';
import { actions } from 'react-redux-form';
import { TransitionGroup, CSSTransition} from 'react-transition-group';
import { Jumbotron } from 'reactstrap';
import Image from 'react-bootstrap/Image';
import { logoTransparentUrl, backgroundUrls } from '../shared/baseUrl';

// map redux store state into props that become available to component
const mapStateToProps = state => {
  return {
    products: state.products,
    applications: state.applications,
    items: state.items,
    samples: state.samples,
    locations: state.locations
  }    
}

const mapDispatchToProps = (dispatch) => ({
  // that object is given as param to dispatch function
  // this map can be used within our component here
  fetchProducts: () => {dispatch(fetchProducts())},
  fetchApplications: () => {dispatch(fetchApplications())},
  fetchItems: () => {dispatch(fetchItems())},
  fetchSamples: () => {dispatch(fetchSamples())},
  fetchLocations: () => {dispatch(fetchLocations())},
  resetFeedbackForm: () => { dispatch(actions.reset('feedback'))}
});

class Main extends Component{

  constructor(props){
    super(props);

    this.state = {
      hasLoaded: false,
      activeLink: "home",
      pageIndex: 0,
      bgImages: [backgroundUrls.homeBackground, backgroundUrls.aboutBackground, backgroundUrls.productBackground,
        backgroundUrls.applicationBackground, backgroundUrls.contactBackground, backgroundUrls.mapBackground],
      jumboTexts: [
        "Mars International, Inc.",
        "What do we do?",
        "What do we provide?",
        "Applying our products?",
        "How can you reach us?",
        "Where do we work?"
      ], 
      jumboTextSmalls: [
        "International traders of recycled plastic",
        "Who are we?",
        "Take a look at our products",
        "We provide suitable products for your industry",
        "We're always eager to hear from you",
        "Dealing with over 25 countries and growing"
      ]

    }

    this.handleChangeLink = this.handleChangeLink.bind(this);
    this.getActiveLink = this.getActiveLink.bind(this);
  }

  handleChangeLink(val, index){
    this.setState({
      activeLink: val,
      pageIndex: index
    })
  }

  getActiveLink(path){
    var link="products";
    var index = 2
    if(path==="/home" || path==="/"){
      link = "home";
      index = 0;
    } else if(path === "/aboutus"){
      link = "about";
      index = 1;
    } else if(path.includes("/applications")){
      link = "application";
      index = 3;
    } else if(path === "/contactus"){
      link = "contact";
      index = 4;
    } else if(path === "/map"){
      link = "map";
      index = 5;
    }
    
    this.handleChangeLink(link, index);
  }

  componentDidMount() {
    const location = window.location;
    if(location !== undefined){
      var path = location.hash;
      path = path.replace("#", "");
      this.getActiveLink(path);
    }
    // good time to fetch data
    this.props.fetchProducts();
    this.props.fetchApplications();
    this.props.fetchItems();
    this.props.fetchSamples();
    this.props.fetchLocations();
  }

  render(){

    const items_sorted = sortList(this.props.items.items);

    function sortList(list){
        if(list.length === 0){
            return list;
        }
        return list.sort(function(a,b){
            if(a.sortOrder < b.sortOrder) return -1;
            if(a.sortOrder > b.sortOrder) return 1;
            return 0;
        })
    }
    
    const HomePage = () => {
		return (
        <Home onChangeLink = {this.handleChangeLink}/>
      )
    }
    
    const AboutPage = () => {
      return (
        <About/>
      )
    }

    const ProductPage = () => {
        const props_sorted = sortList(this.props.products.products)
        
      return (
        <Product products={props_sorted} 
            isLoading={this.props.locations.isLoading}
            errMess={this.props.locations.errMess}
            items = {items_sorted}/>
      )
    }

    const ApplicationPage = () => {
        const apps_sorted = sortList(this.props.applications.applications)
      return (
        <Application applications={apps_sorted}
            isLoading={this.props.locations.isLoading}
            errMess={this.props.locations.errMess}
            items = {items_sorted}/>
      )
    }

    const MapPage = () => {
      return (
        <MapChart locations={this.props.locations.locations}
                    isLoading={this.props.locations.isLoading}
                    errMess={this.props.locations.errMess}/>
      )
    }
  
   // get the param required
   const SelectedItem = ({match}) => {
    const samples_sorted = sortList(this.props.samples.samples)

    return (
      <Item samples={samples_sorted}
              items = {items_sorted}
              itemId = {match.params.itemId}
        />
    );
   }

  return (
      <>
        <Jumbotron className={this.state.pageIndex===0?"jumboMainHome" : "jumboMain"} style={{backgroundImage: `url(${this.state.bgImages[this.state.pageIndex]})`}}>
        <Header activeLink={this.state.activeLink} onChangeLink={this.handleChangeLink} applications={this.state.applicationList}/>
            <center>
              <div className="col-md-12 jumboImage">
                <Image className="logoImg" src={logoTransparentUrl} style={{color: "white"}} alt="Mars International Inc."/>
              </div>
              <div className="col-md-12" style={{"height": "40px"}}/>
                <div className="col-md-12 jumboTextDiv">
                    <span className="jumboText">{this.state.jumboTexts[this.state.pageIndex]}</span><br/>
                    <span className="jumboTextSmall">{this.state.jumboTextSmalls[this.state.pageIndex]}</span>
                </div>
            </center>
        </Jumbotron>
        <div className="pageDiv">
          <TransitionGroup>
            <CSSTransition classNames="page" timeout={300}>
            <Switch>
              {/* path ending with home */}
              <Route path="/" exact component={HomePage}/>
              <Route path="/home" component={HomePage} />
              <Route path="/aboutus" component={AboutPage} />
              <Route path="/products" component={ProductPage} />
              <Route path="/applications" component={ApplicationPage} />
              <Route path="/contactus" component = {() => <Contact resetFeedbackForm={this.props.resetFeedbackForm} onChangeLink={this.handleChangeLink}/> } />
              {/* {routeProducts} */}
              <Route path="/items/:itemId" component={SelectedItem} />
              <Route path="/map" component = {() => <MapPage /> } />
              <Redirect to="/home"/>
            </Switch>
            </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer onChangeLink={this.handleChangeLink} />
        </>
      );
  }
}

// this is how you connect component to react router
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));