import React from 'react';
import {storage} from "../firebase/firebase"
import { Button, Label, Col, Row } from 'reactstrap';
import { Control, Form, Errors } from 'react-redux-form';
import FeedbackDataService from '../shared/feedback.service';
import {googleApiKey } from '../shared/baseUrl';

// checks to see that value length is >0
const required = (val) => val && val.length;
// ensures length <= max Length
const maxLength = (len) => (val) => !(val) || (val.length <= len)
const minLength = (len) => (val) => !(val) || (val.length >= len)

function handleSubmit(values, props, setShowResults) {
    var data = {
        first_name: values.firstname,
        last_name: values.lastname,
        contact: values.telnum,
        email: values.email,
        feedback: values.message,
        imagesUrl: []
      };
  
    //   props.resetFeedbackForm();
    FeedbackDataService.create(data)
        .then(response => {
            var feedbackId = response.data.id;
            var imagesArray = values.image;
            if(imagesArray !== undefined){
                var imagesAsFiles = Array.from(values.image);
                imagesAsFiles.map((imageAsFile) => {
                const uploadTask = storage.ref(`/images/feedback/${values.firstname + " " + values.lastname}/${imageAsFile.name}`).put(imageAsFile)
                //initiates the firebase side uploading 
                uploadTask.on('state_changed', 
                    (snapShot) => {
                    //takes a snap shot of the process as it is happening
                    console.log(snapShot)
                    }, (err) => {
                    //catches the errors
                    console.log(err)
                    }, () => {
                    // gets the functions from storage refences the image storage in firebase by the children
                    // gets the download url then sets the image from firebase as the value for the imgUrl key:
                    storage.ref(`/images/feedback/${values.firstname + " " + values.lastname}/${imageAsFile.name}`).getDownloadURL()
                        .then(fireBaseUrl => {
                            const newImagesArray = data.imagesUrl.concat(fireBaseUrl)
                            data.imagesUrl = newImagesArray;
                            FeedbackDataService.update(feedbackId, data);

                            setShowResults(true)
                            props.resetFeedbackForm();
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                )
                return uploadTask;
                });
            } else {
                setShowResults(true)
                props.resetFeedbackForm();
            }
        })
        .catch(e => {
        setShowResults(false);
          console.log(e);
    });
}

const Contact = (props) => {

    const [showResults, setShowResults] = React.useState(false)
    // const onClick = () => setShowResults(true)

    return(
        <div className="container">
            <div className="row row-content">
                <div className="col-12">
                    <h2>Send an Enquiry / Sell to us / Send us your feedback </h2>
                    <hr/>
                    <p style={{fontSize: "20px"}}>Would you like to sell to us? We purchase from <u>all corners of the world</u>. <br/>
                    Fill the form and we'll get back to you within <b>1 business day</b>.</p>
                </div>
                <div className="col-12 col-md-9">
                    <Form model="feedback" onSubmit={(values) => handleSubmit(values, props, setShowResults)}>
                        {/* Allows you to define a row of the form */}
                        <Row className="form-group">
                            <Label htmlFor="firstname" className="required" md={3}>First Name</Label>
                            <Col md={9}>
                                <Control.text model=".firstname" id="firstname" name="firstname"
                                    placeholder="First Name"
                                    className="form-control"
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(15)
                                    }}
                                        />
                                <Errors
                                    className="text-danger"
                                    model=".firstname"
                                    show="touched"
                                    messages={{
                                        required: 'Required',
                                        minLength: 'Must be greater than 2 characters',
                                        maxLength: 'Must be 15 characters or less'
                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="lastname" className="required" md={3}>Last Name</Label>
                            <Col md={9}>
                                <Control.text model=".lastname" id="lastname" name="lastname"
                                    placeholder="Last Name"
                                    className="form-control"
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(15)
                                    }}
                                        />
                                <Errors
                                    className="text-danger"
                                    model=".lastname"
                                    show="touched"
                                    messages={{
                                        required: 'Required',
                                        minLength: 'Must be greater than 2 characters',
                                        maxLength: 'Must be 15 characters or less'
                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="telnum" className="required" md={3}>Contact Tel.</Label>
                            <Col md={9}>
                                <Control.text model=".telnum" id="telnum" name="telnum"
                                    placeholder="Tel. Number"
                                    className="form-control"
                                    pattern="^[0-9 +-]*$"
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(15)
                                    }}
                                        />
                                <Errors
                                    className="text-danger"
                                    model=".telnum"
                                    show="touched"
                                    messages={{
                                        required: 'Required',
                                        minLength: 'Must be greater than 2 numbers',
                                        maxLength: 'Must be 15 numbers or less'                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="email" className="required" md={3}>Email</Label>
                            <Col md={9}>
                                <Control.text model=".email" id="email" name="email"
                                    placeholder="Email"
                                    className="form-control"
                                    validators={{
                                        required
                                    }}
                                        />
                                <Errors
                                    className="text-danger"
                                    model=".email"
                                    show="touched"
                                    messages={{
                                        required: 'Required'
                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="message" className="required" md={3}>Your Message</Label>
                            <Col md={9}>
                                <Control.textarea model=".message" id="message" 
                                    name="message"
                                    className="form-control"
                                    rows="12"
                                    placeholder="Your message"
                                    validators={{
                                        required
                                    }}/>
                                <Errors
                                    className="text-danger"
                                    model=".message"
                                    show="touched"
                                    messages={{
                                        required: 'Required'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Label htmlFor="image" md={3}>Upload images</Label>
                            <Col md={9}>
                                <Control.file model=".image" id="image" 
                                    name="image"
                                    className="form-control"
                                    multiple
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col md={{size: 9, offset: 3}}>
                                <Button type="submit" color="primary" >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <div id="results">
                        { showResults ? <p> Your message has been sent successfully </p> : null }
                    </div>
                </div>
            </div>

            <hr/>
            <div className="row row-content">
                <div className="col-12 col-md-12">
                    <h2>Other ways to reach us..</h2>
                </div>
                <div className="col-sm-12 col-md-6">
                    <iframe width="600" height="300" frameBorder="0" title="KolkataMap" crossOrigin="anonymous"
                        src={"https://www.google.com/maps/embed/v1/place?key="+googleApiKey+
                        "&q=13,+Elgin+Rd,+Gaza+Park,+Sreepally,+Bhowanipore,+Kolkata,+West+Bengal+700020,+India"} allowFullScreen>
                    </iframe>
                </div>
                <div className="col-sm-12 offset-md-2 col-md-4">
                    <h2>Call us <i className="fa fa-phone fa-sm"></i></h2> +91 33 22834087/22834088 <br/><br/>
                    <h2>Mail us <i className="fa fa-envelope fa-sm"></i></h2>
                    <span><a className="email_link" href="mailto:sales@marsinternational.net">sales@marsinternational.net</a><br/>
                        <a className="email_link" href="mailto:purchase@marsinternational.net">purchase@marsinternational.net</a></span>
                    
                </div>
            </div>
        </div>
    );
}

export default Contact;