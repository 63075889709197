// define all action types using same pattern
// can import action types in reducer functions and use it in switch statement
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCTS_FAILED = 'PRODUCTS_FAILED';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';

export const APPLICATIONS_LOADING = 'APPLICATIONS_LOADING';
export const APPLICATIONS_FAILED = 'APPLICATIONS_FAILED';
export const ADD_APPLICATIONS = 'ADD_APPLICATIONS';

export const ITEMS_LOADING = 'ITEMS_LOADING';
export const ITEMS_FAILED = 'ITEMS_FAILED';
export const ADD_ITEMS = 'ADD_ITEMS';

export const SAMPLES_LOADING = 'SAMPLES_LOADING';
export const SAMPLES_FAILED = 'SAMPLES_FAILED';
export const ADD_SAMPLES = 'ADD_SAMPLES';

export const LOCATIONS_LOADING = 'LOCATIONS_LOADING';
export const LOCATIONS_FAILED = 'LOCATIONS_FAILED';
export const ADD_LOCATIONS = 'ADD_LOCATIONS';
