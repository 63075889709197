import React, { Component } from 'react';
import { Navbar, Nav, NavbarToggler, Collapse, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Headroom from 'react-headroom';

class Header extends Component{

    constructor(props){
        super(props);

        this.state = {
            isNavOpen: false,
            isDropdownOpen: false
        }

        // When you need to use this function in JSX, need to bind it like this
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleDrop = this.toggleDrop.bind(this);
    }

    toggleNav(){
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    toggleDrop(){
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    }

    render(){

        const activeLink = this.props.activeLink;

        return (
            <>
            <Headroom>
                <Navbar light expand="md" style={{width: "100%",  whiteSpace: "nowrap"}}>
                        {/* Or can use arrow function in onClick */}
                    <NavbarToggler className="ml-auto hidden-sm-up float-xs-right" style={{color: "black"}} onClick={this.toggleNav}/>  
                        {/* if false then hidden else shown */}
                    <Collapse isOpen={this.state.isNavOpen} navbar>
                        <Nav className="d-flex flex-column flex-md-row" style={{width: "100%"}}>
                            <NavItem onClick={this.toggleNav}>
                                <NavLink className={"nav-link nav-top" + (activeLink === "home" ? " active_item" : "")} to="/home"  onClick={() => this.props.onChangeLink("home", 0)}>
                                    <span className={"fa fa-home fa-lg" + (activeLink === "home" ? " active_span" : "")}> Home</span>
                                </NavLink>
                            </NavItem>
                            <NavItem onClick={this.toggleNav}>
                                <NavLink className={"nav-link nav-top" + (activeLink === "about" ? " active_item" : "")} to="/aboutus"  onClick={() => this.props.onChangeLink("about", 1)}>
                                    <span className={"fa fa-info fa-lg" + (activeLink === "about" ? " active_span" : "")}> About us</span>
                                </NavLink>
                            </NavItem>
                            <NavItem onClick={this.toggleNav}>
                                <NavLink className={"nav-link nav-top" + (activeLink === "products" ? " active_item" : "")} to="/products"  onClick={() => this.props.onChangeLink("products", 2)}>
                                    <span className={"fa fa-list fa-lg" + (activeLink === "products" ? " active_span" : "")}> Products </span>
                                </NavLink>
                            </NavItem>
                            <NavItem onClick={this.toggleNav}>
                                <NavLink className={"nav-link nav-top" + (activeLink === "application" ? " active_item" : "")} to="/applications"  onClick={() => this.props.onChangeLink("application", 3)}>
                                    <span className={"fa fa-recycle fa-lg" + (activeLink === "application" ? " active_span" : "")}> Applications </span>
                                </NavLink>
                            </NavItem>
                            <NavItem onClick={this.toggleNav}>
                                <NavLink className={"nav-link nav-top" + (activeLink === "map" ? " active_item" : "")} to="/map"  onClick={() => this.props.onChangeLink("map", 5)}>
                                    <span className={"fa fa-map-marker fa-lg" + (activeLink === "map" ? " active_span" : "")}> Our reach</span>
                                </NavLink>
                            </NavItem>
                            <NavItem onClick={this.toggleNav}>
                                <NavLink className={"nav-link nav-top" + (activeLink === "contact" ? " active_item" : "")} to="/contactus"  onClick={() => this.props.onChangeLink("contact", 4)}>
                                    <span className={"fa fa-address-card fa-lg" + (activeLink === "contact" ? " active_span" : "")}> Contact us</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
                </Headroom>
            </>
        )
    }
}

export default Header;