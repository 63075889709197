import * as ActionTypes from './ActionTypes';

export const Samples = (state = {
    isLoading: true, 
    errMess: null,
    samples: []
    }, action) => {
    switch (action.type) {
        case ActionTypes.ADD_SAMPLES: 
            return {...state, isLoading: false, errMess: null, samples: action.payload}

        case ActionTypes.SAMPLES_LOADING:
            return {...state, isLoading: true, errMess: null, samples: []}

        case ActionTypes.SAMPLES_FAILED:
            return {...state, isLoading: false, errMess: action.payload, samples: []}

        default:
          return state;
    }
};