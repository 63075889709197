import React from 'react';
import { Card, CardBody, CardTitle, Button, CardImgOverlay } from 'reactstrap';
import { Loading } from './Loading';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Accordion from "./Accordion";
import { ErrorFetching } from "./ErrorFetching";
import { NothingToShow } from './NothingToShow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RenderItem({item}){
    return (
            <Card key={item.id} className="itemCard">
                <Link to= {`/items/${item.id}`}>
                    
                    <CardImgOverlay className="cardImgOverlay">
                        <span id="certSpanCirc">
                            {item.bestSeller? <FontAwesomeIcon icon="medal" size="lg"/> : <span/>}
                        </span>
                    </CardImgOverlay>

                    <Image className="cardImgDark" src={item.imageUrl} alt={item.title}/>
                    <CardBody className="cardBody">
                        <Button className="cardButton"><CardTitle style={{textTransform: "uppercase", fontWeight: "bold"}}> {item.title} </CardTitle></Button>
                    </CardBody>
                </Link>
            </Card>
    );
}

function isInApplication(arr, val){
    return arr.some((item) => item === val);
}

function Application(props){
        
    if(props.isLoading){
        return (
            <div className="productDiv">
                <Loading/>
            </div>
        )
    } else if(props.errMess){
        return (
            <div className="productDiv">
                <ErrorFetching/>
            </div>
        );
    } else{
        return (
            <div className="productDiv">
                <Accordion allowMultipleOpen>
                    {props.applications.map((application) => {
                    const itemList = props.items.filter((items) => isInApplication(items.applicationTitles, application.title));
                    return (
                    <div key={application.title} label={application.title} smallText={itemList.length+" items"} style={{padding: "10px"}} isOpen={application.title === props.applications[0].title}>
                        <div className="row" style={{marginTop: "10px"}}>
                            <div className="col-sm-6 col-md-3">
                                <div className="row">
                                    {application.imagesUrl.map(imageUrl => 
                                        <div key={imageUrl} className="col-md-12">
                                            <Image src={imageUrl} key={imageUrl} className="appImg" style={{marginTop: "10px", marginLeft: "10px"}}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr className="horizontalRow" style={{border: "1px solid black", width: '80%', marginLeft: 0}} />
                            <div className="verticalLine"/>
                            <div className="col-md-8">
                                <div className="row">
                                    {itemList.length > 0?
                                        itemList.map(item => 
                                            <div key={item.imageUrl} className="col-6 col-md-4 col-lg-3">
                                                <RenderItem key={item.id} item={item}/>
                                            </div>
                                        )
                                        : 
                                        <NothingToShow/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>);
                })}
                </Accordion>
        </div>
        );
    }
}

export default Application;