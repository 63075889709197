import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return(
    <center>
    <div className="footer">
        <div className="row justify-content-center">             
            <span>
                <Link className="footerAnchor" to = "/" onClick={() => props.onChangeLink("home", 0)}>
                    Home | 
                </Link>
                <Link className="footerAnchor" to = "/aboutus" onClick={() => props.onChangeLink("about", 1)}>
                    About | 
                </Link>
                <Link className="footerAnchor" to = "/products" onClick={() => props.onChangeLink("products", 2)}>
                    Product | 
                </Link>
                <Link className="footerAnchor" to = "/contactus" onClick={() => props.onChangeLink("contact", 4)}>
                    Contact | 
                </Link>
                <Link className="footerAnchor" to = "/map" onClick={() => props.onChangeLink("map", 4)}>
                    Reach
                </Link>
            </span>
        </div>
        <div className="row justify-content-center">             
            <a className="iconA" href="https://wa.me/919432495058" target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp fa-lg social-i-web"></i></a>
            <a className="iconA" href="https://join.skype.com/invite/aFR7gDlA7Fbp/" target="_blank" rel="noopener noreferrer"><i className="fa fa-skype fa-lg social-i-web"></i></a>
            <a className="iconA" href="https://www.facebook.com/marsinternationalinc" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook fa-lg social-i-web"></i></a>
            <a className="iconA" href="https://www.linkedin.com/company/mars-international-inc/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin fa-lg social-i-web"></i></a>
        </div>      
        <div className="row justify-content-center">             
            <div className="col-auto rightsDiv">
                <p>&copy;All rights reserved to Mars International, Inc.</p>
            </div>
        </div>
    </div>
    <div className="footerSocial">
        <div className="row" style={{paddingLeft: "10px", paddingRight: "10px"}}>
            <div className="col-3" style={{padding: "0px"}}>
                <button className="iconButton" style={{backgroundColor: "#25d366"}} href="https://wa.me/919432495058" target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp fa-lg social-i"></i></button>
            </div>
            <div className="col-3" style={{padding: "0px"}}>
                <button className="iconButton" style={{backgroundColor: "#00aff0"}} href="https://join.skype.com/invite/aFR7gDlA7Fbp/" target="_blank" rel="noopener noreferrer"><i className="fa fa-skype fa-lg social-i"></i></button>
            </div>
            <div className="col-3" style={{padding: "0px"}}>
                <button className="iconButton" style={{backgroundColor: "#3b5998"}} href="https://www.facebook.com/marsinternationalinc" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook fa-lg social-i"></i></button>
            </div>
            <div className="col-3" style={{padding: "0px"}}>
                <button className="iconButton" style={{backgroundColor: "#0e76a8"}} href="https://www.linkedin.com/company/mars-international-inc/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin fa-lg social-i"></i></button>
            </div>
        </div>
    </div>
    </center>
    )
}

export default Footer;