import * as ActionTypes from './ActionTypes';

export const Applications = (state = {
    isLoading: true, 
    errMess: null,
    applications: []
    }, action) => {
    switch (action.type) {
        case ActionTypes.ADD_APPLICATIONS: 
            return {...state, isLoading: false, errMess: null, applications: action.payload}

        case ActionTypes.APPLICATIONS_LOADING:
            return {...state, isLoading: true, errMess: null, applications: []}

        case ActionTypes.APPLICATIONS_FAILED:
            return {...state, isLoading: false, errMess: action.payload, applications: []}

        default:
          return state;
    }
};