import * as ActionTypes from './ActionTypes';
import { baseUrl } from '../shared/baseUrl';

// create as a thunk. Returns a function. Rest of the action creators return actions
export const fetchProducts = () => (dispatch) => {
    // 
    dispatch(productsLoading(true));

    // Can use axios here.
    return fetch(baseUrl + 'products')
        // server responds with errors
        .then(response => {
            if(response.ok){  //if status is in 200s
                return response;
            }
            else{
                var error = new Error('Error '+response.status+': '+response.statusText)
                error.response = response;
                throw error;
            }
        },
            // server doesn't respond at all
        error => {
            var errmess = new Error(error.message);
            throw errmess;
        })
        // get response from the previous .then()
        .then(response => response.json())
        // the json file is now available in products
        .then(products => dispatch(addProducts(products)))
        // catch error that you threw earlier
        .catch(error => dispatch(productsFailed(error.message)));
}

export const productsLoading = () => ({
    type: ActionTypes.PRODUCTS_LOADING
});

export const productsFailed = (errmess) => ({
    type: ActionTypes.PRODUCTS_FAILED,
    payload: errmess
});

export const addProducts = (products) => ({
    type: ActionTypes.ADD_PRODUCTS,
    payload: products
});

// create as a thunk. Returns a function. Rest of the action creators return actions
export const fetchApplications = () => (dispatch) => {
    // 
    dispatch(applicationsLoading(true));

    // Can use axios here.
    return fetch(baseUrl + 'applications')
        // server responds with errors
        .then(response => {
            if(response.ok){  //if status is in 200s
                return response;
            }
            else{
                var error = new Error('Error '+response.status+': '+response.statusText)
                error.response = response;
                throw error;
            }
        },
            // server doesn't respond at all
        error => {
            var errmess = new Error(error.message);
            throw errmess;
        })
        // get response from the previous .then()
        .then(response => response.json())
        // the json file is now available in applications
        .then(applications => dispatch(addApplications(applications)))
        // catch error that you threw earlier
        .catch(error => dispatch(applicationsFailed(error.message)));
}

export const applicationsLoading = () => ({
    type: ActionTypes.APPLICATIONS_LOADING
});

export const applicationsFailed = (errmess) => ({
    type: ActionTypes.APPLICATIONS_FAILED,
    payload: errmess
});

export const addApplications = (applications) => ({
    type: ActionTypes.ADD_APPLICATIONS,
    payload: applications
});

//Items
// create as a thunk. Returns a function. Rest of the action creators return actions
export const fetchItems = () => (dispatch) => {
    // 
    dispatch(itemsLoading(true));

    // Can use axios here.
    return fetch(baseUrl + 'items')
        // server responds with errors
        .then(response => {
            if(response.ok){  //if status is in 200s
                return response;
            }
            else{
                var error = new Error('Error '+response.status+': '+response.statusText)
                error.response = response;
                throw error;
            }
        },
            // server doesn't respond at all
        error => {
            var errmess = new Error(error.message);
            throw errmess;
        })
        // get response from the previous .then()
        .then(response => response.json())
        // the json file is now available in items
        .then(items => dispatch(addItems(items)))
        // catch error that you threw earlier
        .catch(error => dispatch(itemsFailed(error.message)));
}

export const itemsLoading = () => ({
    type: ActionTypes.ITEMS_LOADING
});

export const itemsFailed = (errmess) => ({
    type: ActionTypes.ITEMS_FAILED,
    payload: errmess
});

export const addItems = (items) => ({
    type: ActionTypes.ADD_ITEMS,
    payload: items
});


// Samples
export const fetchSamples = () => (dispatch) => {
    // 
    dispatch(samplesLoading(true));

    // Can use axios here.
    return fetch(baseUrl + 'samples')
        // server responds with errors
        .then(response => {
            if(response.ok){  //if status is in 200s
                return response;
            }
            else{
                var error = new Error('Error '+response.status+': '+response.statusText)
                error.response = response;
                throw error;
            }
        },
            // server doesn't respond at all
        error => {
            var errmess = new Error(error.message);
            throw errmess;
        })
        // get response from the previous .then()
        .then(response => response.json())
        // the json file is now available in samples
        .then(samples => dispatch(addSamples(samples)))
        // catch error that you threw earlier
        .catch(error => dispatch(samplesFailed(error.message)));
}

export const samplesLoading = () => ({
    type: ActionTypes.SAMPLES_LOADING
});

export const samplesFailed = (errmess) => ({
    type: ActionTypes.SAMPLES_FAILED,
    payload: errmess
});

export const addSamples = (samples) => ({
    type: ActionTypes.ADD_SAMPLES,
    payload: samples
});


// Locations

// Samples
export const fetchLocations = () => (dispatch) => {
    // 
    dispatch(locationsLoading(true));

    // Can use axios here.
    return fetch(baseUrl + 'locations')
        // server responds with errors
        .then(response => {
            if(response.ok){  //if status is in 200s
                return response;
            }
            else{
                var error = new Error('Error '+response.status+': '+response.statusText)
                error.response = response;
                throw error;
            }
        },
            // server doesn't respond at all
        error => {
            var errmess = new Error(error.message);
            throw errmess;
        })
        // get response from the previous .then()
        .then(response => response.json())
        // the json file is now available in locations
        .then(locations => dispatch(addLocations(locations)))
        // catch error that you threw earlier
        .catch(error => dispatch(locationsFailed(error.message)));
}

export const locationsLoading = () => ({
    type: ActionTypes.LOCATIONS_LOADING
});

export const locationsFailed = (errmess) => ({
    type: ActionTypes.LOCATIONS_FAILED,
    payload: errmess
});

export const addLocations = (locations) => ({
    type: ActionTypes.ADD_LOCATIONS,
    payload: locations
});