import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyA6e7KQ4rXeWbGNgpe8Cop9LsWEwRUUluc",
  authDomain: "mars-international-inc.firebaseapp.com",
  databaseURL: "https://mars-international-inc.firebaseio.com",
  projectId: "mars-international-inc",
  storageBucket: "mars-international-inc.appspot.com",
  messagingSenderId: "360766377991",
  appId: "1:360766377991:web:0315cee372e01c1a1b9aa2",
  measurementId: "G-MGD5CGJVDC"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const storage = firebase.storage()

export  {
	storage, firebase as default
  }